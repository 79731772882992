header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    border-bottom: 2px solid rgba($white, 0.1);
    &.transparent {
        border-bottom: 2px solid rgba($white, 0.1);
        &.active {
            .header-bottom {
                background-color: rgba($primary-color, 0.5);
                .submenu {
                    background-color: rgba($primary-color, 0.5) !important;
                    // backdrop-filter: blur(2.5px);
                }
            }
            border-bottom: none;
        }
    }
    &.minimized {
        border-bottom: 2px solid rgba($white, 0.1);
        .header-top {
            max-height: 0 !important;
            padding: 0;
            .wrapper {
                opacity: 0;
            }
        }
        .header-bottom {
            position: relative;
            z-index: 10;
            //background-color: $primary-color;
        }
    }
    &.checkout-header {
        background-color: $primary-color;
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 0;
            @media (min-width: 992px) {
                padding: 23px 0;
            }
            .header-logo {
                flex: 0 0 100px;
                width: 100px;
                @media (min-width: 1200px) {
                    flex: unset;
                    width: unset;
                }
            }
            .links {
                display: flex;
                align-items: center;
                margin-left: auto;
                margin-right: 40px;
                .phone {
                    font-size: 1.6rem;
                    font-family: $openSansSemibold;
                    color: #a9b3c3;
                    display: block;
                    margin-right: 36px;
                }
                .login {
                    padding: 8.5px 20px;
                    @media (min-width: 992px) {
                        padding: 9px 26px;
                    }
                }
            }
            .language-select {
                position: relative;
                z-index: 2;
                &.toggled {
                    .selected-option {
                        .arrow {
                            transform: rotateX(180deg);
                        }
                    }
                    .options {
                        transform: scaleY(1);
                    }
                }
                .selected-option {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    position: relative;
                    z-index: 1;
                    color: #a9b3c3;
                    font-family: $openSansSemibold;
                    .pin {
                        display: block;
                        margin-right: 8px;
                    }
                    .arrow {
                        display: block;
                        margin-left: 10px;
                        transition: all 0.3s ease-in;
                    }
                }
                .options {
                    position: absolute;
                    left: 0;
                    top: 120%;
                    padding: 5px 0;
                    background-color: $blue;
                    //border: 0.5px solid $white;
                    box-shadow: 0 3px 10px $blue;
                    transform: scaleY(0);
                    transform-origin: top;
                    transition: all 0.3s ease-in;
                    .option {
                        display: block;
                        padding: 10px 15px;
                        white-space: nowrap;
                        cursor: pointer;
                        transition: all 0.2s ease-out;
                        color: #a9b3c3;
                        &:hover {
                            background-color: lighten($blue, 40%);
                            color: $white;
                        }
                        &.active {
                            background-color: lighten($blue, 15%);
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    &.selfcare-header {
        background: linear-gradient(180deg, #15284f 41.72%, #08152a 100%);
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 13px 0;
            @media (min-width: 992px) {
                padding: 23px 0;
            }
            .header-logo {
                flex: 0 0 100px;
                width: 100px;
                @media (min-width: 1200px) {
                    flex: unset;
                    width: unset;
                }
            }
            .toggler {
                // margin-right: 23px;
                display: block;
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }
    }
    &.has-promo-ribbon ~ main {
        margin-top: 170px;
        @media (min-width: 1200px) {
            margin-top: 190px;
        }
    }

    &.has-promo-ribbon.active ~ main {
        margin-top: 110px;
        @media (min-width: 1200px) {
            margin-top: 138px;
        }
    }

    &.has-promo-ribbon.transparent.active ~ main {
        margin-top: 95px;
        @media (min-width: 1200px) {
            margin-top: 106px;
        }
    }

    .header-top {
        background-color: $blue;
        color: #a9b3c3;
        font-family: $openSansBold;
        padding: 15px 0;
        max-height: 100px;
        transition: all 0.3s linear;
        @media (min-width: 992px) {
            font-family: $openSansSemibold;
        }
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .phone-contact-wrapper {
                margin-left: auto;
                font-size: 1.2rem;
                display: flex;
                align-items: center;
                @media (min-width: 992px) {
                    font-size: 1.4rem;
                }
                .phone {
                    color: inherit;
                    display: block;
                    padding-right: 13px;
                    margin-right: 13px;
                    border-right: 1px solid rgba($white, 0.3);
                }
                .top-menu-link {
                    color: inherit;
                    display: block;
                }
            }
        }
    }
    .header-bottom {
        background-color: $primary-color;
        color: $white;
        padding: 20px 0;
        transition: all 0.3s ease;
        @media (min-width: 1200px) {
            position: relative;
            padding: 0;
        }
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .toggler {
                // margin-right: 23px;
                display: block;
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
            .logo {
                display: block;
                flex: 0 0 100px;
                width: 100px;
                @media (min-width: 1200px) {
                    margin-right: 60px;
                    flex: unset;
                    width: unset;
                }
            }
            .header-menu-wrapper {
                position: fixed;
                top: -100vh;
                left: 0;
                width: 100%;
                height: 100%;
                overflow-y: auto;
                background-color: $primary-color;
                padding: 56px 15px;
                transition: all 0.3s ease;
                &.visible {
                    top: 0;
                }
                @media (min-width: 1200px) {
                    position: static;
                    top: unset;
                    left: unset;
                    width: unset;
                    height: unset;
                    padding: unset;
                    background-color: transparent;
                    overflow-y: unset;
                }
                .close-menu {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    display: block;
                    margin-bottom: 40px;
                }
                .header-menu {
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 20px;
                    @media (min-width: 1200px) {
                        display: flex;
                        align-items: center;
                        margin-bottom: 0;
                    }
                    > .menu-item {
                        padding: 10px 0;
                        // position: relative;
                        transition: all 0.2s ease;
                        @media (min-width: 1200px) {
                            margin-right: 44px;
                            padding: 20px 0;
                            position: relative;
                        }
                        @media (min-width: 1400px) {
                            margin-right: 66px;
                        }
                        &:hover {
                            > .menu-link {
                                @media (min-width: 768px) {
                                    // filter: drop-shadow(0 0 12px $gray);
                                    color: darken($white, 0.2);
                                    text-decoration: underline;
                                    .toggler {
                                        transform: rotateX(180deg);
                                    }
                                }
                            }
                            .submenu {
                                @media (min-width: 768px) {
                                    transform: scaleY(1);
                                    max-height: 1000px;
                                    opacity: 1;
                                    .menu-link {
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        &.active {
                            > .menu-link {
                                text-decoration: underline;
                                // color: $red2;
                                // filter: drop-shadow(0 0 12px $white);
                            }
                        }
                        &.toggled {
                            > .menu-link {
                                .toggler {
                                    transform: rotateX(180deg);
                                }
                            }
                            .submenu {
                                transform: scaleY(1);
                                max-height: 1000px;
                                opacity: 1;
                            }
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: -15px;
                            display: block;
                            height: 2px;
                            width: calc(100% + 30px);
                            background: linear-gradient(
                                180deg,
                                rgba(65, 102, 180, 0.2) 0%,
                                rgba(22, 41, 80, 0.2) 100%
                            );
                            @media (min-width: 1200px) {
                                display: none;
                            }
                        }
                        .menu-link {
                            display: flex;
                            // align-items: center;
                            font-size: 1.8rem;
                            font-family: $openSansSemibold;
                            letter-spacing: -0.18px;
                            color: $white;
                            padding: 10px 0;
                            transition: all 0.2s ease;
                            @media (min-width: 1200px) {
                                font-size: 1.6rem;
                                letter-spacing: -0.16px;
                            }
                            .text {
                                flex: 1;
                                width: auto;
                            }
                            .toggler {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                margin-left: 10px;
                                // padding: 5px;
                                flex: 0 0 30px;
                                width: 30px;
                                transition: all 0.3s linear;
                                svg {
                                    fill: $white;
                                    width: 15px;
                                    height: auto;
                                }
                            }
                        }
                        .submenu {
                            transform: scaleY(0);
                            max-height: 0;
                            opacity: 0;
                            transition: all 0.3s linear;
                            transform-origin: top;
                            list-style: none;
                            @media (min-width: 1200px) {
                                position: absolute;
                                width: auto;
                                top: calc(100% - 1px);
                                left: -30px;
                                padding: 22px 50px 30px;
                                border: 2px solid rgba($white, 0.1);
                                border-radius: 0 0 10px 10px;
                                background-color: $primary-color;
                            }
                            .menu-item {
                                &:hover {
                                    > .menu-link {
                                        text-decoration: underline;
                                    }
                                }
                                &.active {
                                    > .menu-link {
                                        text-decoration: underline;
                                    }
                                }
                                .menu-link {
                                    font-size: 1.6rem;
                                    letter-spacing: -0.16px;
                                    // font-family: $openSansRegular;
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
                .button-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    //justify-content: space-between;
                    position: relative;
                    padding-bottom: 30px;
                    margin-bottom: 20px;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: -15px;
                        display: block;
                        height: 2px;
                        width: calc(100% + 30px);
                        background: linear-gradient(
                            180deg,
                            rgba(65, 102, 180, 0.2) 0%,
                            rgba(22, 41, 80, 0.2) 100%
                        );
                    }
                    .top-menu-link {
                        flex: 0 0 100%;
                        width: 100%;
                        font-family: $openSansSemibold;
                        letter-spacing: -0.18px;
                        font-size: 1.8rem;
                        margin-bottom: 40px;
                        color: $white;
                    }
                    a {
                        &:not(.top-menu-link) {
                            padding: 8px 28px;
                            font-size: 1.4rem;
                            font-family: $openSansBold;
                        }
                    }
                }
            }
            .button-wrapper {
                margin-left: auto;
                @media (min-width: 1200px) {
                    display: flex;
                }
                .login-link {
                    margin-right: 16px;
                }
            }
        }
    }

    .country-language-wrapper {
        .country-select,
        .language-select {
            margin-bottom: 20px;
            position: relative;
            z-index: 2;
            @media (min-width: 1200px) {
                margin-bottom: 0;
            }
            &.toggled {
                .selected-option {
                    .arrow {
                        transform: rotateX(180deg);
                    }
                }
                .options {
                    transform: scaleY(1);
                }
            }
            .selected-option {
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                z-index: 1;
                .pin {
                    display: block;
                    margin-right: 8px;
                }
                .arrow {
                    display: block;
                    margin-left: 10px;
                    transition: all 0.3s ease-in;
                }
            }
            .options {
                position: absolute;
                left: 0;
                top: 120%;
                padding: 5px 0;
                background-color: $blue;
                //border: 0.5px solid $white;
                box-shadow: 0 3px 10px $blue;
                transform: scaleY(0);
                transform-origin: top;
                transition: all 0.3s ease-in;
                .option {
                    display: block;
                    padding: 10px 15px;
                    white-space: nowrap;
                    cursor: pointer;
                    transition: all 0.2s ease-out;
                    &:hover {
                        background-color: lighten($blue, 40%);
                        color: $white;
                    }
                    &.active {
                        background-color: lighten($blue, 15%);
                        color: $white;
                    }
                }
            }
        }
        .country-select {
            @media (min-width: 1200px) {
                margin-right: 20px;
                padding-right: 20px;
                border-right: 1px solid rgba($white, 0.3);
            }
            .options {
                bottom: 120%;
                top: unset;
                transform-origin: bottom;
                @media (min-width: 1200px) {
                    transform-origin: top;
                    bottom: unset;
                    top: 120%;
                    bottom: unset;
                }
            }
        }
        .language-select {
            margin-bottom: 0;
            .options {
                top: 120%;
            }
        }
    }
}

@import './promo-ribbon';