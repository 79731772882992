.error-section {
    background-color: #f5f5f5;
    padding: 48px 0;
    @media (min-width: 992px) {
        padding: 67px 0 74px;
    }
    .wrapper {
        max-width: 1140px;
        margin-inline: auto;
        @media (min-width: 992px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .content {
            margin-bottom: 32px;
            @media (min-width: 992px) {
                flex: 0 0 40%;
                width: 40%;
                margin-bottom: 0;
            }
            .section-head {
                color: $primary-color;
                text-align: left;
                margin-bottom: 21px;
                @media (min-width: 992px) {
                    margin-bottom: 42px;
                }
                .section-title {
                    text-align: left;
                }
                .description {
                    font-size: 1.4rem;
                    letter-spacing: -0.28px;
                    line-height: 1.44;
                    @media (min-width: 992px) {
                        font-size: 1.6rem;
                        letter-spacing: -0.4px;
                    }
                    @media (min-width: 1200px) {
                        font-size: 2rem;
                        letter-spacing: -0.6px;
                    }
                }
            }
            .description {
                // p {
                //     font-size: 1.4rem;
                //     line-height: 1.44;
                //     letter-spacing: -0.28px;
                // }
                a {
                    color: #162950;
                }
            }
            .danger-button {
                display: inline-block;
            }
        }
        .image {
            border-radius: 12px;
            overflow: hidden;
            &::after {
                padding-top: 65%;
                background-color: transparent;
            }
            @media (min-width: 992px) {
                flex: 0 0 50%;
                width: 50%;
            }
        }
    }
}
