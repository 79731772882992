@font-face {
    font-family: 'Open Sans Light';
    src: url('./fonts/open-sans/OpenSans-Light.eot');
    src: local('Open Sans Light'), local('OpenSans-Light'),
        url('./fonts/open-sans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('./fonts/open-sans/OpenSans-Light.woff2') format('woff2'),
        url('./fonts/open-sans/OpenSans-Light.woff') format('woff'),
        url('./fonts/open-sans/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

$openSansLight: 'Open Sans Light', sans-serif;

@font-face {
    font-family: 'Open Sans Semibold';
    src: url('./fonts/open-sans/OpenSans-Semibold.eot');
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
        url('./fonts/open-sans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/open-sans/OpenSans-Semibold.woff2') format('woff2'),
        url('./fonts/open-sans/OpenSans-Semibold.woff') format('woff'),
        url('./fonts/open-sans/OpenSans-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

$openSansSemibold: 'Open Sans Semibold', sans-serif;

@font-face {
    font-family: 'Open Sans Bold';
    src: url('./fonts/open-sans/OpenSans-Bold.eot');
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('./fonts/open-sans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/open-sans/OpenSans-Bold.woff2') format('woff2'),
        url('./fonts/open-sans/OpenSans-Bold.woff') format('woff'),
        url('./fonts/open-sans/OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

$openSansBold: 'Open Sans Bold', sans-serif;

@font-face {
    font-family: 'Open Sans Regular';
    src: url('./fonts/open-sans/OpenSans-Regular.eot');
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('./fonts/open-sans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('./fonts/open-sans/OpenSans-Regular.woff2') format('woff2'),
        url('./fonts/open-sans/OpenSans-Regular.woff') format('woff'),
        url('./fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$openSansRegular: 'Open Sans Regular', sans-serif;

