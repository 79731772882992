
/* colors */
$primary-color: #08152A;
$secondary-color: #07152A;
$white: #fff;
$gray: #C8C8C8;
$red: #E31E25;
$red2: #EC1C24;
$blue: #1B305C;
$green: #26a300;
$orange: #fab700;
$black: #000;
$gray: #F5F5F5;

@function colorYiq($color){   
    $r: red($color);
    $g: green($color);
    $b: blue($color);   
    $yiq : $r*0.299 + $g*0.587 + $b*0.114;  
    @if($yiq < 150){
        @return #fff;
    }@else{
        @return #000;
    }  
}