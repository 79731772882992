.promo-ribbon {
    position: relative;
    // padding: 5px 0;
    height: 49px;
    overflow: hidden;
    @media (min-width: 992px) {
        height: 55px;
    }
    .wrapper {
        padding: 5px 0;
        height: 49px;
        @media (min-width: 992px) {
            height: 55px;
        }
        .promo-ribbon-icon {
            // max-width: 120px;
            flex: 0 0 120px;
            width: 120px;
            // height: 30px;
            &::after {
                padding-top: 25%;
                background-color: transparent;
            }
            @media (min-width: 1200px) {
                // flex: 0 0 220px;
                // width: 220px;
                flex: 0 0 180px;
                width: 180px;
            }
            img {
                width: 100%;
                object-fit: contain;
            }
        }
        .content {
            flex: 1;
            width: auto;
            .ribbon-title {
                font-family: $openSansSemibold;
                font-size: 1.6rem;
            }
            // .inner-content {
            //     margin-top: 5px;
            // }
            p {
                font-size: 1.4rem;
                line-height: 1;
            }
            a {
                color: inherit;
                text-decoration: underline;
            }
        }
        .counter-wrapper {
            flex: 0 0 200px;
            width: 200px;
            @media (min-width: 576px) {
                flex: 0 0 330px;
                width: 330px;
            }
            @media (min-width: 1200px) {
                flex: 0 0 440px;
                width: 440px;
            }
            .counter-text {
                font-size: 1.6rem;
                line-height: 2;
                // text-transform: uppercase;
                font-family: $openSansBold;
                margin-right: 10px;
            }
            .counter {
                > span {
                    font-size: 1.8rem;
                    font-family: $openSansBold;
                    align-self: center;
                    display: block;
                    margin-inline: 5px;
                    // @media (min-width: 1200px) {
                    //     font-size: 3.2rem;
                    // }
                }
                .counter-item {
                    flex: 0 0 40px;
                    width: 40px;
                    text-align: center;
                    // line-height: 1.6;
                    letter-spacing: -0.37px;
                    text-transform: uppercase;
                    display: flex;
                    flex-direction: column;
                    @media (min-width: 1200px) {
                        flex: 0 0 60px;
                        width: 60px;
                        letter-spacing: -0.24px;
                    }
                    .value {
                        font-size: 1.8rem;
                        font-family: $openSansBold;
                        // @media (min-width: 1200px) {
                        //     font-size: 3.2rem;
                        // }
                    }
                    .type {
                        font-size: 1rem;
                        display: inline-block;
                        // @media (min-width: 1200px) {
                        //     font-size: 1.2rem;
                        // }
                    }
                }
            }
        }
        .call-to-action {
            margin-left: auto;
            margin-right: 10px;
            // box-shadow: 0px 0px 3px 2px darken($red, 10%);
            font-family: $openSansBold;
            background-color: rgba(#333333, 0.5);
            @media (min-width: 992px) {
                margin-left: 10px;
            }
        }
    }
}
