@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-50%);
    }
}
@mixin bordering($name, $color) {
    @keyframes #{$name} {
        0% {
            border-top: 0 solid rgba($color, 0.1);
            border-right: 0 solid $color;
            border-bottom: 0 solid $color;
            border-left: 0 solid $color;
        }
        10% {
            border-top: 0 solid rgba($color, 0.2);
            border-right: 0 solid $color;
            border-bottom: 0 solid $color;
            border-left: 0 solid $color;
        }
        20% {
            border-top: 0.5px solid rgba($color, 0.3);
            border-right: 0 solid $color;
            border-bottom: 0 solid $color;
            border-left: 0 solid $color;
        }
        30% {
            border-top: 1px solid rgba($color, 0.5);
            border-right: 0 solid $color;
            border-bottom: 0 solid $color;
            border-left: 0 solid $color;
        }
        40% {
            border-top: 1px solid $color;
            border-right: 0.5px solid $color;
            border-bottom: 0 solid $color;
            border-left: 0 solid $color;
        }
        50% {
            border-top: 1px solid $color;
            border-right: 1px solid $color;
            border-bottom: 0 solid $color;
            border-left: 0 solid $color;
        }
        60% {
            border-top: 1px solid $color;
            border-right: 1px solid $color;
            border-bottom: 0.5px solid $color;
            border-left: 0 solid $color;
            top: -0.5px;
        }
        70% {
            border-top: 1px solid $color;
            border-right: 1px solid $color;
            border-bottom: 1px solid $color;
            border-left: 0 solid $color;
            top: -1px;
        }
        80% {
            border-top: 1px solid $color;
            border-right: 1px solid $color;
            border-bottom: 1px solid $color;
            border-left: 0.5px solid $color;
            // top: -1px;
            left: -0.5px;
        }
        90% {
            border-top: 1px solid $color;
            border-right: 1px solid $color;
            border-bottom: 1px solid $color;
            border-left: 1px solid $color;
            // top: -1px;
            left: -1px;
        }
        100% {
            border-top: 1px solid $color;
            border-right: 1px solid $color;
            border-bottom: 1px solid $color;
            border-left: 1px solid $color;
            // top: -1px;
            left: -1px;
        }
    }
}

@keyframes appear {
    0% {
        transform: scale(0);
        opacity: 0;
        max-height: 0px;
    }
    50% {
        transform: scale(0.5);
        opacity: 0.5;
        max-height: 250px;
    }
    100% {
        transform: scale(1);
        opacity: 1;
        max-height: 500px;
    }
}

@keyframes dissappear {
    0% {
        transform: scale(1);
        opacity: 1;
        max-height: 500px;
    }
    50% {
        transform: scale(0.5);
        opacity: 0.5;
        max-height: 250px;
    }
    100% {
        transform: scale(0);
        opacity: 0;
        max-height: 0px;
    }
}

@keyframes positioning1 {
    0% {
        position: absolute;
        opacity: 1;
    }
    25% {
        position: absolute;
        opacity: 0.5;
    }
    50% {
        position: absolute;
        opacity: 0;
    }
    75% {
        position: static;
        opacity: 0;
    }
    100% {
        position: static;
        opacity: 1;
    }
}

@keyframes positioning2 {
    0% {
        position: static;
        opacity: 1;
        z-index: 3;
    }
    25% {
        position: static;
        opacity: 0.5;
        z-index: 2;
    }
    50% {
        position: absolute;
        opacity: 0;
        z-index: 1;
    }
    75% {
        position: absolute;
        z-index: 2;
    }
    100% {
        position: absolute;
        opacity: 1;
        z-index: 3;
    }
}

@keyframes zindex {
    0% {
       z-index: 5; 
       opacity: 1;
    }
    20% {
        z-index: 4;
        opacity: 0.8;
    }
    40% {
        z-index: 3;
        opacity: 0.6;
    }
    60% {
        z-index: 2;
        opacity: 0.4;
    }
    80% {
        z-index: 1;
        opacity: 0.2;
    }
    100% {
        z-index: 0;
        opacity: 0;
    }
}
