@import "./animations";
@include bordering(borderRound, #4166b4);

footer {
    background-color: $primary-color;
    color: #a9b3c3;
    padding: 52px 0 45px;
    &.checkout-footer {
        background-color: transparent;
        padding: 0;
        position: relative;
        z-index: 2;
        &.border {
            border-top: 2px solid rgba(#a9b3c3, 0.5);
            .wrapper {
                border-top: none;
            }
        }
        .wrapper {
            padding: 24px 0 150px;
            border-top: 2px solid rgba(#a9b3c3, 0.5);
            @media (min-width: 992px) {
                padding: 24px 0;
            }
            @media (min-width: 1200px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 35px 0 42px;
            }
            .footer-menu {
                margin-bottom: 30px;
                @media (min-width: 1200px) {
                    margin-bottom: 0;
                }
                .footer-bottom-menu {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    list-style: none;
                    padding-left: unset;
                    .menu-item {
                        margin-bottom: 10px;
                        @media (min-width: 1200px) {
                            margin-bottom: 0;
                        }
                        &:not(:last-of-type) {
                            // padding-right: 20px;
                            // border-right: 2px solid rgba($white, 0.1);
                            margin-right: 20px;
                            @media (min-width: 1200px) {
                                padding-right: 20px;
                                border-right: 2px solid rgba($white, 0.1);
                            }
                        }
                        &.active {
                            > .menu-link {
                                filter: drop-shadow(0 0 12px $white);
                                text-decoration: underline;
                                color: $white;
                            }
                        }
                        &:hover {
                            > .menu-link {
                                text-decoration: underline;
                            }
                        }
                        .menu-link {
                            // color: #a9b3c3;
                            color: #162950;
                            font-size: 1.4rem;
                            //line-height: 1.8;
                            @media (min-width: 992px) {
                                font-size: 1.6rem;
                            }
                        }
                    }
                }
            }
            .copyright {
                color: #162950;
                text-align: center;
                @media (min-width: 1200px) {
                    text-align: left;
                }
            }
        }
    }
    .footer-top {
        .wrapper {
            @media (min-width: 992px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 36px;
                border-bottom: 2px solid rgba($white, 0.1);
            }
            .telekom-group-title {
                margin-inline: 50px auto;
                color: $white;
                text-transform: uppercase;
            }
        }
    }
    .footer-middle {
        .wrapper {
            @media (min-width: 992px) {
                padding: 52px 0 32px;
            }
            @media (min-width: 1200px) {
                display: flex;
                justify-content: space-between;
                border-bottom: 2px solid rgba($white, 0.1);
            }
            .footer-menu-wrapper {
                @media (min-width: 1200px) {
                    flex: 0 0 calc(75% - 25px);
                    width: calc(75% - 25px);
                }
                .footer-menu {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    list-style: none;
                    padding-left: unset;
                    // border-top: 1px solid $gray;
                    > .menu-item {
                        flex: 0 0 100%;
                        width: 100%;
                        // margin-bottom: 50px;
                        // border-top: 1px solid $gray;
                        border-top: 1px solid rgba($white, 0.1);
                        padding: 20px 0;
                        position: relative;
                        &:hover {
                            > .menu-link {
                                text-decoration: underline;
                            }
                        }
                        &.active {
                            > .menu-link {
                                // filter: drop-shadow(0 0 12px $white);
                                // color: $red2;
                                text-decoration: underline;
                            }
                        }
                        &.toggled {
                            .arrow {
                                transform: rotateX(180deg);
                            }
                            .submenu {
                                transform: scaleY(1);
                                max-height: 2000px;
                                margin-top: 20px;
                            }
                        }
                        @media (min-width: 992px) {
                            flex: 0 0 30%;
                            width: 30%;
                            border-top: none;
                            padding: 0;
                            padding-bottom: 50px;
                        }
                        .menu-link {
                            color: $white;
                            font-family: $openSansBold;
                            font-size: 1.4rem;
                            letter-spacing: -0.28px;
                            @media (min-width: 992px) {
                                font-size: 1.6rem;
                            }
                        }
                        .arrow {
                            width: 40px;
                            height: 63px;
                            position: absolute;
                            z-index: 1;
                            right: 0;
                            top: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            transition: all 0.2s linear;
                            @media (min-width: 992px) {
                                display: none;
                            }
                        }
                        .submenu {
                            list-style: none;
                            padding-left: unset;
                            transition: all 0.2s linear;
                            // margin-top: 20px;
                            transform: scaleY(0);
                            max-height: 0;
                            transform-origin: top;
                            @media (min-width: 992px) {
                                transform: unset;
                                max-height: unset;
                                margin-top: 25px;
                            }
                            .menu-item {
                                &:not(:last-of-type) {
                                    margin-bottom: 15px;
                                }
                                &:hover {
                                    > .menu-link {
                                        text-decoration: underline;
                                    }
                                }
                                &.active {
                                    > .menu-link {
                                        // filter: drop-shadow(0 0 12px $white);
                                        // color: $red2;
                                        text-decoration: underline;
                                        color: $white;
                                    }
                                }
                                .menu-link {
                                    color: #a9b3c3;
                                    font-family: $openSansRegular;
                                }
                            }
                        }
                    }
                }
            }
            .newsletter-form {
                padding: 34px 0;
                border-top: 2px solid rgba($white, 0.1);
                border-bottom: 2px solid rgba($white, 0.1);
                @media (min-width: 1200px) {
                    flex: 0 0 calc(25% - 25px);
                    width: calc(25% - 25px);
                    padding: unset;
                    border: none;
                }
                .newsletter-title {
                    letter-spacing: -0.28px;
                    margin-bottom: 16px;
                    color: $white;
                    text-transform: none;
                    @media (min-width: 576px) {
                        text-align: center;
                    }
                    @media (min-width: 992px) {
                        margin-bottom: 25px;
                    }
                    @media (min-width: 1200px) {
                        text-align: unset;
                    }
                }
                .form-group {
                    margin-bottom: 20px;
                    max-width: 400px;
                    margin-inline: auto;
                    @media (min-width: 1200px) {
                        max-width: unset;
                        margin-inline: unset;
                        margin-bottom: 24px;
                    }
                    label {
                        display: flex;
                        align-items: flex-start;
                        p {
                            font-size: 1.2rem;
                            line-height: 1.42;
                            letter-spacing: -0.36px;
                            @media (min-width: 1200px) {
                                font-size: 1.4rem;
                                letter-spacing: -0.42px;
                            }
                        }
                        a {
                            color: inherit;
                            text-decoration: underline;
                        }
                    }
                    .form-control {
                        background-color: transparent;
                        font-size: 1.4rem;
                        color: $white;
                        line-height: 4.4rem;
                        padding-inline: 22px;
                        @media (min-width: 992px) {
                            font-size: 1.6rem;
                            line-height: 4.9rem;
                        }
                        &::placeholder {
                            color: #a9b3c3;
                        }
                    }
                }
                .submit-button {
                    display: block;
                    width: 100%;
                    max-width: 400px;
                    margin-inline: auto;
                    background-color: #15284f;
                    border-radius: 6px;
                    text-align: center;
                    color: #a9b3c3;
                    font-size: 1.6rem;
                    font-family: $openSansBold;
                    padding: 14px;
                    border: 1px solid transparent;
                    cursor: pointer;
                    position: relative;
                    &::after {
                        content: "";
                        width: calc(100% + 2px);
                        height: calc(100% + 2px);
                        position: absolute;
                        left: -1px;
                        top: -1px;
                        border-radius: 6px;
                    }
                    &:hover {
                        &::after {
                            animation: borderRound 0.5s ease 1 forwards;
                        }
                    }
                    @media (min-width: 1200px) {
                        max-width: unset;
                        margin-inline: unset;
                        padding: 17px;
                    }
                }
            }
            #mc_embed_shell {
                padding: 34px 0;
                border-top: 2px solid rgba($white, 0.1);
                border-bottom: 2px solid rgba($white, 0.1);
                @media (min-width: 1200px) {
                    flex: 0 0 calc(25% - 25px);
                    width: calc(25% - 25px);
                    padding: unset;
                    border: none;
                }
                #mc_embed_signup {
                    background: inherit;
                    width: auto;
                    #mc-embedded-subscribe-form {
                        padding: 0 !important;
                        margin: 0;
                        #mc-embedded-subscribe {
                            display: block;
                            width: 100%;
                            max-width: 400px;
                            margin-inline: auto;
                            background-color: #15284f;
                            border-radius: 6px;
                            text-align: center;
                            color: #a9b3c3;
                            font-size: 1.6rem;
                            font-family: $openSansBold;
                            padding: 14px;
                            border: 1px solid transparent;
                            cursor: pointer;
                            position: relative;
                            &::after {
                                content: "";
                                width: calc(100% + 2px);
                                height: calc(100% + 2px);
                                position: absolute;
                                left: -1px;
                                top: -1px;
                                border-radius: 6px;
                            }
                            &:hover {
                                &::after {
                                    animation: borderRound 0.5s ease 1 forwards;
                                }
                            }
                        }
                        @media (min-width: 1200px) {
                            max-width: unset;
                            margin-inline: unset;
                            padding: 17px;
                        }
                        #mc_embed_signup_scroll {
                            & > h2 {
                                color: #fff;
                                font-family: "Open Sans Bold", sans-serif;
                                font-size: 1.6rem;
                                letter-spacing: -0.28px;
                                text-align: center;
                                @media (min-width: 1200px) {
                                    text-align: left;
                                }
                            }
                            .form-checkbox {
                                max-width: 400px;
                                margin: 0 auto;
                                margin-bottom: 24px;
                                .fake-checkbox {
                                    display: inline-block;
                                }
                                label > p {
                                    font-size: 14px;
                                    display: inline-block;
                                    a {
                                        color: #a9b3c3 !important;
                                        text-decoration: underline;
                                        &:visited {
                                            color: #a9b3c3;
                                        }
                                    }
                                }
                            }
                            .mc-field-group {
                                margin-bottom: 20px;
                                max-width: 400px;
                                margin-inline: auto;
                                @media (min-width: 1200px) {
                                    max-width: unset;
                                    margin-inline: unset;
                                    margin-bottom: 24px;
                                }
                                label {
                                    display: flex;
                                    align-items: flex-start;
                                    p {
                                        font-size: 1.2rem;
                                        line-height: 1.42;
                                        letter-spacing: -0.36px;
                                        @media (min-width: 1200px) {
                                            font-size: 1.4rem;
                                            letter-spacing: -0.42px;
                                        }
                                    }
                                    a {
                                        color: inherit;
                                        text-decoration: underline;
                                    }
                                }
                                input {
                                    border: 2px solid rgba(255, 255, 255, 0.1);
                                    margin-top: 25px;
                                    border-radius: 6px;
                                    width: 100%;
                                    background-color: transparent;
                                    font-size: 1.4rem;
                                    color: $white;
                                    line-height: 4.4rem;
                                    padding-inline: 22px;
                                    @media (min-width: 992px) {
                                        font-size: 1.6rem;
                                        line-height: 4.9rem;
                                    }
                                    &::placeholder {
                                        color: #a9b3c3;
                                    }
                                }
                                div.mce_inline_error {
                                    font-family: "Open Sans Regular", sans-serif !important;
                                    font-size: 1.4rem;
                                    font-weight: 500;
                                    font-style: italic;
                                    background: none;
                                    color: #e31e25;
                                    padding-left: 0;
                                }
                            }
                            #mce-responses {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }

    .footer-bottom {
        .wrapper {
            padding-top: 24px;
            @media (min-width: 1200px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 40px;
            }
            .telekom-logo {
                display: block;
                margin-bottom: 17px;
                @media (min-width: 1200px) {
                    margin-bottom: 0;
                }
            }
            .footer-bottom-menu-wrapper {
                margin-bottom: 30px;
                @media (min-width: 1200px) {
                    margin-bottom: 0;
                }
                .footer-bottom-menu {
                    display: flex;
                    flex-wrap: wrap;
                    list-style: none;
                    padding-left: unset;
                    .menu-item {
                        margin-bottom: 10px;
                        @media (min-width: 1200px) {
                            margin-bottom: 0;
                        }
                        &:not(:last-of-type) {
                            padding-right: 20px;
                            border-right: 2px solid rgba($white, 0.1);
                            margin-right: 20px;
                        }
                        &.active {
                            > .menu-link {
                                // filter: drop-shadow(0 0 12px $white);
                                text-decoration: underline;
                                color: $white;
                            }
                        }
                        &:hover {
                            > .menu-link {
                                text-decoration: underline;
                            }
                        }
                        .menu-link {
                            color: #a9b3c3;
                            font-size: 1.4rem;
                            //line-height: 1.8;
                            @media (min-width: 992px) {
                                font-size: 1.6rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .social-menu {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .social-menu-title {
            flex: 0 0 100%;
            width: 100%;
            margin-bottom: 25px;
            color: $white;
            line-height: normal;
            @media (min-width: 992px) {
                flex: unset;
                width: unset;
                margin-right: 25px;
                margin-bottom: 0;
            }
        }
        .social-menu-link {
            svg {
                // fill: #a9b3c3;
                @media (min-width: 992px) {
                    fill: $white;
                }
            }
            &:not(:last-of-type) {
                margin-right: 25px;
                margin-bottom: 10px;
                @media (min-width: 992px) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
